<template>
  <v-avatar
    rounded
    size="44"
    class="mr-2"
  >
    <v-img
      :src="product.photo_url"
      :alt="product.name + ' - ' + product.sku"
      :title="product.name + ' - ' + product.sku"
      aspect-ratio="1"
    />
  </v-avatar>
</template>

<script>
export default {
  name: 'ItemAvatar',
  props: {
    product: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>

<style scoped>

</style>
