<template>
  <v-dialog
    :value="openedValue"
    max-width="700px"
    @click:outside="close()"
    @keydown.esc="close()"
  >
    <v-card>
      <v-card-title>
        {{ title }}
      </v-card-title>
      <v-card-text>
        <v-row dense>
          <v-col
            cols="12"
            md="6"
            sm="6"
            xs="12"
          >
            <v-text-field
              v-model="address.recipient_name"
              class="pt-0"
              :label="$t('views.shipment.forms.label.address.recipient_name')"
              outlined
              dense
            />
          </v-col>
          <v-col
            cols="12"
            md="6"
            sm="6"
            xs="12"
          >
            <v-text-field
              v-model="address.company"
              class="pt-0"
              :label="$t('views.shipment.forms.label.address.company')"
              outlined
              dense
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col
            cols="12"
            md="12"
            sm="12"
            xs="12"
          >
            <v-text-field
              v-model="address.line1"
              class="pt-0"
              :label="$t('views.shipment.forms.label.address.address_line1')"
              outlined
              dense
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col
            cols="12"
            md="12"
            sm="12"
            xs="12"
          >
            <v-text-field
              v-model="address.line2"
              class="pt-0"
              :label="$t('views.shipment.forms.label.address.address_line2')"
              outlined
              dense
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col
            cols="12"
            md="6"
            sm="6"
            xs="12"
          >
            <v-text-field
              v-model="address.postal_code"
              class="pt-0"
              :label="$t('views.shipment.forms.label.address.postal_code')"
              outlined
              dense
            />
          </v-col>
          <v-col
            cols="12"
            md="6"
            sm="6"
            xs="12"
          >
            <v-text-field
              v-model="address.city"
              class="pt-0"
              :label="$t('views.shipment.forms.label.address.city')"
              outlined
              dense
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col
            cols="12"
            md="6"
            sm="6"
            xs="12"
          >
            <v-text-field
              v-model="address.state"
              class="pt-0"
              :label="$t('views.shipment.forms.label.address.state')"
              outlined
              dense
            />
          </v-col>
          <v-col
            cols="12"
            md="6"
            sm="6"
            xs="12"
          >
            <v-select
              v-model="address.country_code"
              :items="getCountryList()"
              item-text="label"
              item-value="code"
              class="pt-0"
              :label="$t('views.shipment.forms.label.address.country_code')"
              outlined
              dense
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col
            cols="12"
            md="12"
            sm="12"
            xs="12"
          >
            <v-text-field
              v-model="address.additional_information"
              class="pt-0"
              :label="$t('views.shipment.forms.label.address.additional_information')"
              outlined
              dense
            />
          </v-col>
        </v-row>
        <v-row
          dense
          class="mt-2"
        >
          <v-col
            cols="12"
            md="6"
            sm="6"
            xs="12"
          >
            <v-text-field
              v-model="emailValue"
              class="pt-0"
              :label="$t('views.shipment.forms.label.email')"
              outlined
              dense
            />
          </v-col>
          <v-col
            cols="12"
            md="6"
            sm="6"
            xs="12"
          >
            <v-text-field
              v-model="phoneValue"
              class="pt-0"
              :label="$t('views.shipment.forms.label.phone')"
              outlined
              dense
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          outlined
          color="red darken-2"
          @click="close()"
        >
          <v-icon left>
            mdi-cancel
          </v-icon>{{ $t('forms.buttons.cancel') }}
        </v-btn>
        <v-spacer />
        <v-btn
          raised
          color="green darken-1"
          class="white--text"
          @click="submit()"
        >
          <v-icon left>
            mdi-check-outline
          </v-icon>{{ $t('forms.buttons.validate') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { COUNTRY_CODES, COUNTRY_NAMES } from '@/helpers/countries'

export default {
  name: 'AddressModal',
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    address: {
      type: Object,
      default: () => ({}),
    },
    email: {
      type: String,
      default: null,
    },
    phone: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      emailValue: this.email,
      phoneValue: this.phone,
    }
  },
  computed: {
    openedValue: {
      get () { return this.opened  },
      set (value) { this.$emit('update:opened', value) },
    },
  },
  methods: {
    getCountryList() {
      return COUNTRY_CODES
        .map(code => ({
          code: code.alpha2,
          label: COUNTRY_NAMES[code.alpha3],
        }))
        .sort((a, b) => a.label.localeCompare(b.label))
    },
    close() {
      this.phoneValue = this.phone
      this.emailValue = this.email
      this.loading = false
      this.openedValue = false
    },
    submit() {
      this.$emit('submited', {
        address: this.address,
        phone: this.phoneValue,
        email: this.emailValue,
      })
      this.close()
    },
  },
}
</script>

<style scoped>

</style>
