<template>
  <v-dialog
    :value="openedValue"
    max-width="900px"
    @click:outside="close()"
    @keydown.esc="close()"
  >
    <v-card
      :loading="loading"
      :disabled="loading"
    >
      <v-card-title>Déplacer vers un colis existant</v-card-title>
      <v-card-text>
        <v-row
          justify="center"
          class="py-2"
          no-gutters
        >
          <v-col
            cols="12"
          >
            <v-simple-table
              class="lh_shipment__details"
            >
              <tbody>
                <tr>
                  <td>Produits</td>
                  <td>
                    <ItemAvatar
                      v-for="item in items"
                      :key="item.id"
                      :product="item.product"
                    />
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-data-table
              v-model="selected"
              :headers="headers"
              :items="shipmentsAvailable"
              single-select
              item-key="id"
              show-select
              hide-default-footer
            >
              <template v-slot:item.products="{ item }">
                <ItemAvatar
                  v-for="order_item in item.order_items"
                  :key="order_item.id"
                  :product="order_item.product"
                />
              </template>
              <template v-slot:item.reference="{ item }">
                <a
                  :href="`/shipments/` + item.id"
                  target="_blank"
                  :title="`id: ` + item.id"
                >
                  {{ item.reference }}
                </a>
              </template>
              <template v-slot:item.logistic="{ item }">
                <ProviderIcon :provider="item.provider" />
                <CarrierIcon :carrier="item.carrier" />
                <TrackingNumberLink
                  :tracking-number="item.tracking_number"
                  :tracking-url="item.tracking_url"
                />
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          outlined
          color="red darken-2"
          @click="close()"
        >
          <v-icon left>
            mdi-cancel
          </v-icon>
          {{ $t('forms.buttons.cancel') }}
        </v-btn>
        <v-spacer />
        <v-btn
          :disabled="selected.length !== 1"
          raised
          color="green darken-1"
          class="white--text"
          @click="move()"
        >
          <v-icon left>
            mdi-check-outline
          </v-icon>
          Déplacer
        </v-btn>
      </v-card-actions>
    </v-card>
    <ConfirmModal ref="confirm" />
  </v-dialog>
</template>

<script>
import CarrierIcon from '@/components/base/Shipment/CarriererIcon.vue'
import ConfirmModal from '@/components/base/Modal/ConfirmModal.vue'
import ItemAvatar from '@/views/Shipments/Edit/ItemAvatar.vue'
import ProviderIcon from '@/components/base/Shipment/ProviderIcon.vue'
import TrackingNumberLink from '@/components/base/Shipment/TrackingNumberLink.vue'

export default {
  name: 'MoveToExistingOneModal',
  components: {
    ItemAvatar,
    CarrierIcon,
    ProviderIcon,
    TrackingNumberLink,
    ConfirmModal,
  },
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
    shipmentSrc: {
      type: Object,
      default: null,
    },
    items: {
      type: Array,
      default: () => ([]),
    },
  },
  data() {
    return {
      loading: false,
      headers: [
        {
          value: 'products',
          text: 'Produit(s)',
          sortable: false,
        },
        {
          value: 'reference',
          text: 'Reference',
          sortable: false,
        },
        {
          value: 'logistic',
          text: 'Logistique',
          sortable: false,
        },
      ],
      shipmentsAvailable: [],
      selected: [],
      message: {
        type: null,
        text: null,
        link: null,
      },
    }
  },
  computed: {
    openedValue: {
      get() {
        return this.opened
      },
      set(value) {
        this.$emit('update:opened', value)
      },
    },
  },
  watch: {
    shipmentSrc(newShipment) {
      this.loading = true
      this.$axios.get('v3/shipments?reference=' + newShipment.reference + '&type[]=' + newShipment.type)
        .then((response) => {
          const results = response.data['hydra:member']
          this.shipmentsAvailable = results.filter((item) => item.id !== newShipment.id) ?? []
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error)
        })
        .finally(
          this.loading = false
        )
    },
  },
  methods: {
    show(loading) {
      this.openedValue = true
      this.loading = loading
    },
    hide() {
      this.openedValue = false
      this.loading = false
      this.message = { type: null, text: null, link: null }
    },
    close() {
      this.$emit('shipmentUpdated', this.message)
      this.hide()
      this.selected = []
      this.shipmentsAvailable = []
    },
    async move() {
      if (this.selected.length !== 1) {
        return
      }
      
      this.hide()

      if (await this.$refs.confirm.open(
        this.$i18n.t('forms.buttons.validate'),
        this.$i18n.t('views.shipment.forms.messages.move_items_to_existing_one',
          { nb: this.items.length }))
      ) {
        this.show(true)
        if (confirm) {
          this.$axios.post('v3/shipments/' + this.shipmentSrc.id + '/reorganize', {
            to_shipment_id: this.selected[0].id,
            items_id: this.items.map((item) => item.id),
          })
            .then((response) => {
              this.message.type = 'green'
              this.message.text = this.$i18n.t('views.shipment.forms.messages.move_succeed')
              this.message.link = '/shipments/' + response?.data?.id
            })
            .catch((error) => {
              // eslint-disable-next-line no-console
              console.log(error)
              let error_message = error.response.data['hydra:description']
                ?? error.response.data['detail'] ?? 'No context'
              this.message.type = 'error'
              this.message.text = this.$i18n.t('views.shipment.forms.messages.move_failed',
                { error: error_message })
            }).finally(
              this.close
            )
        } else {
          this.close()
        }
      }
    },
  },
}
</script>

<style scoped>
.lh_shipment__details tr td:first-child {
  font-weight: bolder;
  width: 150px;
}
</style>
