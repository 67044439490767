export const COUNTRY_CODES = [
  { alpha3: 'AFG', alpha2: 'AF' },
  { alpha3: 'ALA', alpha2: 'AL' },
  { alpha3: 'ALB', alpha2: 'AX' },
  { alpha3: 'DZA', alpha2: 'DZ' },
  { alpha3: 'ASM', alpha2: 'AS' },
  { alpha3: 'AND', alpha2: 'AD' },
  { alpha3: 'AGO', alpha2: 'AO' },
  { alpha3: 'AIA', alpha2: 'AI' },
  { alpha3: 'ATA', alpha2: 'AQ' },
  { alpha3: 'ATG', alpha2: 'AG' },
  { alpha3: 'ARG', alpha2: 'AR' },
  { alpha3: 'ARM', alpha2: 'AM' },
  { alpha3: 'ABW', alpha2: 'AW' },
  { alpha3: 'AUS', alpha2: 'AU' },
  { alpha3: 'AUT', alpha2: 'AT' },
  { alpha3: 'AZE', alpha2: 'AZ' },
  { alpha3: 'BHS', alpha2: 'BS' },
  { alpha3: 'BHR', alpha2: 'BH' },
  { alpha3: 'BGD', alpha2: 'BD' },
  { alpha3: 'BRB', alpha2: 'BB' },
  { alpha3: 'BLR', alpha2: 'BY' },
  { alpha3: 'BEL', alpha2: 'BE' },
  { alpha3: 'BLZ', alpha2: 'BZ' },
  { alpha3: 'BEN', alpha2: 'BJ' },
  { alpha3: 'BMU', alpha2: 'BM' },
  { alpha3: 'BTN', alpha2: 'BT' },
  { alpha3: 'BOL', alpha2: 'BO' },
  { alpha3: 'BES', alpha2: 'BQ' },
  { alpha3: 'BIH', alpha2: 'BA' },
  { alpha3: 'BWA', alpha2: 'BW' },
  { alpha3: 'BVT', alpha2: 'BV' },
  { alpha3: 'BRA', alpha2: 'BR' },
  { alpha3: 'IOT', alpha2: 'IOT' },
  { alpha3: 'BRN', alpha2: 'BN' },
  { alpha3: 'BGR', alpha2: 'BG' },
  { alpha3: 'BFA', alpha2: 'BF' },
  { alpha3: 'BDI', alpha2: 'BI' },
  { alpha3: 'KHM', alpha2: 'KH' },
  { alpha3: 'CMR', alpha2: 'CM' },
  { alpha3: 'CAN', alpha2: 'CA' },
  { alpha3: 'CPV', alpha2: 'CV' },
  { alpha3: 'CYM', alpha2: 'KY' },
  { alpha3: 'CAF', alpha2: 'CF' },
  { alpha3: 'TCD', alpha2: 'TD' },
  { alpha3: 'CHL', alpha2: 'CL' },
  { alpha3: 'CHN', alpha2: 'CN' },
  { alpha3: 'CXR', alpha2: 'CX' },
  { alpha3: 'CCK', alpha2: 'CC' },
  { alpha3: 'COL', alpha2: 'CO' },
  { alpha3: 'COM', alpha2: 'KM' },
  { alpha3: 'COG', alpha2: 'CG' },
  { alpha3: 'COD', alpha2: 'CD' },
  { alpha3: 'COK', alpha2: 'CK' },
  { alpha3: 'CRI', alpha2: 'CR' },
  { alpha3: 'CIV', alpha2: 'CI' },
  { alpha3: 'HRV', alpha2: 'HR' },
  { alpha3: 'CUB', alpha2: 'CU' },
  { alpha3: 'CUW', alpha2: 'CW' },
  { alpha3: 'CYP', alpha2: 'CY' },
  { alpha3: 'CZE', alpha2: 'CZ' },
  { alpha3: 'DNK', alpha2: 'DK' },
  { alpha3: 'DJI', alpha2: 'DJ' },
  { alpha3: 'DMA', alpha2: 'DM' },
  { alpha3: 'DOM', alpha2: 'DO' },
  { alpha3: 'ECU', alpha2: 'EC' },
  { alpha3: 'EGY', alpha2: 'EG' },
  { alpha3: 'SLV', alpha2: 'SV' },
  { alpha3: 'GNQ', alpha2: 'GQ' },
  { alpha3: 'ERI', alpha2: 'ER' },
  { alpha3: 'EST', alpha2: 'EE' },
  { alpha3: 'ETH', alpha2: 'ET' },
  { alpha3: 'FLK', alpha2: 'FK' },
  { alpha3: 'FRO', alpha2: 'FO' },
  { alpha3: 'FJI', alpha2: 'FJ' },
  { alpha3: 'FIN', alpha2: 'FI' },
  { alpha3: 'FRA', alpha2: 'FR' },
  { alpha3: 'GUF', alpha2: 'GF' },
  { alpha3: 'PYF', alpha2: 'PF' },
  { alpha3: 'ATF', alpha2: 'TF' },
  { alpha3: 'GAB', alpha2: 'GA' },
  { alpha3: 'GMB', alpha2: 'GM' },
  { alpha3: 'GEO', alpha2: 'GE' },
  { alpha3: 'DEU', alpha2: 'DE' },
  { alpha3: 'GHA', alpha2: 'GH' },
  { alpha3: 'GIB', alpha2: 'GI' },
  { alpha3: 'GRC', alpha2: 'GR' },
  { alpha3: 'GRL', alpha2: 'GL' },
  { alpha3: 'GRD', alpha2: 'GD' },
  { alpha3: 'GLP', alpha2: 'GP' },
  { alpha3: 'GUM', alpha2: 'GU' },
  { alpha3: 'GTM', alpha2: 'GT' },
  { alpha3: 'GGY', alpha2: 'GG' },
  { alpha3: 'GIN', alpha2: 'GN' },
  { alpha3: 'GNB', alpha2: 'GW' },
  { alpha3: 'GUY', alpha2: 'GY' },
  { alpha3: 'HTI', alpha2: 'HT' },
  { alpha3: 'HMD', alpha2: 'HM' },
  { alpha3: 'VAT', alpha2: 'VA' },
  { alpha3: 'HND', alpha2: 'HN' },
  { alpha3: 'HKG', alpha2: 'HK' },
  { alpha3: 'HUN', alpha2: 'HU' },
  { alpha3: 'ISL', alpha2: 'IS' },
  { alpha3: 'IND', alpha2: 'IN' },
  { alpha3: 'IDN', alpha2: 'ID' },
  { alpha3: 'IRN', alpha2: 'IR' },
  { alpha3: 'IRQ', alpha2: 'IQ' },
  { alpha3: 'IRL', alpha2: 'IE' },
  { alpha3: 'IMN', alpha2: 'IM' },
  { alpha3: 'ISR', alpha2: 'IL' },
  { alpha3: 'ITA', alpha2: 'IT' },
  { alpha3: 'JAM', alpha2: 'JM' },
  { alpha3: 'JPN', alpha2: 'JP' },
  { alpha3: 'JEY', alpha2: 'JE' },
  { alpha3: 'JOR', alpha2: 'JO' },
  { alpha3: 'KAZ', alpha2: 'KZ' },
  { alpha3: 'KEN', alpha2: 'KE' },
  { alpha3: 'KIR', alpha2: 'KI' },
  { alpha3: 'PRK', alpha2: 'KP' },
  { alpha3: 'KOR', alpha2: 'KR' },
  { alpha3: 'KWT', alpha2: 'KW' },
  { alpha3: 'KGZ', alpha2: 'KG' },
  { alpha3: 'LAO', alpha2: 'LA' },
  { alpha3: 'LVA', alpha2: 'LV' },
  { alpha3: 'LBN', alpha2: 'LB' },
  { alpha3: 'LSO', alpha2: 'LS' },
  { alpha3: 'LBR', alpha2: 'LR' },
  { alpha3: 'LBY', alpha2: 'LY' },
  { alpha3: 'LIE', alpha2: 'LI' },
  { alpha3: 'LTU', alpha2: 'LT' },
  { alpha3: 'LUX', alpha2: 'LU' },
  { alpha3: 'MAC', alpha2: 'MO' },
  { alpha3: 'MKD', alpha2: 'MK' },
  { alpha3: 'MDG', alpha2: 'MG' },
  { alpha3: 'MWI', alpha2: 'MW' },
  { alpha3: 'MYS', alpha2: 'MY' },
  { alpha3: 'MDV', alpha2: 'MV' },
  { alpha3: 'MLI', alpha2: 'ML' },
  { alpha3: 'MLT', alpha2: 'MT' },
  { alpha3: 'MHL', alpha2: 'MH' },
  { alpha3: 'MTQ', alpha2: 'MQ' },
  { alpha3: 'MRT', alpha2: 'MR' },
  { alpha3: 'MUS', alpha2: 'MU' },
  { alpha3: 'MYT', alpha2: 'YT' },
  { alpha3: 'MEX', alpha2: 'MX' },
  { alpha3: 'FSM', alpha2: 'FM' },
  { alpha3: 'MAR', alpha2: 'MA' },
  { alpha3: 'MDA', alpha2: 'MD' },
  { alpha3: 'MCO', alpha2: 'MC' },
  { alpha3: 'MNG', alpha2: 'MN' },
  { alpha3: 'MNE', alpha2: 'ME' },
  { alpha3: 'MSR', alpha2: 'MS' },
  { alpha3: 'MOZ', alpha2: 'MZ' },
  { alpha3: 'MMR', alpha2: 'MM' },
  { alpha3: 'NAM', alpha2: 'NA' },
  { alpha3: 'NRU', alpha2: 'NR' },
  { alpha3: 'NPL', alpha2: 'NP' },
  { alpha3: 'NLD', alpha2: 'NL' },
  { alpha3: 'NCL', alpha2: 'NC' },
  { alpha3: 'NZL', alpha2: 'NZ' },
  { alpha3: 'NIC', alpha2: 'NI' },
  { alpha3: 'NER', alpha2: 'NE' },
  { alpha3: 'NGA', alpha2: 'NG' },
  { alpha3: 'NIU', alpha2: 'NU' },
  { alpha3: 'NFK', alpha2: 'NF' },
  { alpha3: 'MNP', alpha2: 'MP' },
  { alpha3: 'NOR', alpha2: 'NO' },
  { alpha3: 'OMN', alpha2: 'OM' },
  { alpha3: 'PAK', alpha2: 'PK' },
  { alpha3: 'PLW', alpha2: 'PW' },
  { alpha3: 'PSE', alpha2: 'PS' },
  { alpha3: 'PAN', alpha2: 'PA' },
  { alpha3: 'PNG', alpha2: 'PG' },
  { alpha3: 'PRY', alpha2: 'PY' },
  { alpha3: 'PER', alpha2: 'PE' },
  { alpha3: 'PHL', alpha2: 'PH' },
  { alpha3: 'PCN', alpha2: 'PN' },
  { alpha3: 'POL', alpha2: 'PL' },
  { alpha3: 'PRT', alpha2: 'PT' },
  { alpha3: 'PRI', alpha2: 'PR' },
  { alpha3: 'QAT', alpha2: 'QA' },
  { alpha3: 'REU', alpha2: 'RE' },
  { alpha3: 'ROU', alpha2: 'RO' },
  { alpha3: 'RUS', alpha2: 'RU' },
  { alpha3: 'RWA', alpha2: 'RW' },
  { alpha3: 'BLM', alpha2: 'BL' },
  { alpha3: 'SHN', alpha2: 'SH' },
  { alpha3: 'KNA', alpha2: 'KN' },
  { alpha3: 'LCA', alpha2: 'LC' },
  { alpha3: 'MAF', alpha2: 'MF' },
  { alpha3: 'SPM', alpha2: 'PM' },
  { alpha3: 'VCT', alpha2: 'VC' },
  { alpha3: 'WSM', alpha2: 'WS' },
  { alpha3: 'SMR', alpha2: 'SM' },
  { alpha3: 'STP', alpha2: 'ST' },
  { alpha3: 'SAU', alpha2: 'SA' },
  { alpha3: 'SEN', alpha2: 'SN' },
  { alpha3: 'SRB', alpha2: 'RS' },
  { alpha3: 'SYC', alpha2: 'SC' },
  { alpha3: 'SLE', alpha2: 'SL' },
  { alpha3: 'SGP', alpha2: 'SG' },
  { alpha3: 'SXM', alpha2: 'SX' },
  { alpha3: 'SVK', alpha2: 'SK' },
  { alpha3: 'SVN', alpha2: 'SI' },
  { alpha3: 'SLB', alpha2: 'SB' },
  { alpha3: 'SOM', alpha2: 'SO' },
  { alpha3: 'ZAF', alpha2: 'ZA' },
  { alpha3: 'SGS', alpha2: 'GS' },
  { alpha3: 'SSD', alpha2: 'SS' },
  { alpha3: 'ESP', alpha2: 'ES' },
  { alpha3: 'LKA', alpha2: 'LK' },
  { alpha3: 'SDN', alpha2: 'SD' },
  { alpha3: 'SUR', alpha2: 'SR' },
  { alpha3: 'SJM', alpha2: 'SJ' },
  { alpha3: 'SWZ', alpha2: 'SZ' },
  { alpha3: 'SWE', alpha2: 'SE' },
  { alpha3: 'CHE', alpha2: 'CH' },
  { alpha3: 'SYR', alpha2: 'SY' },
  { alpha3: 'TWN', alpha2: 'TW' },
  { alpha3: 'TJK', alpha2: 'TJ' },
  { alpha3: 'TZA', alpha2: 'TZ' },
  { alpha3: 'THA', alpha2: 'TH' },
  { alpha3: 'TLS', alpha2: 'TL' },
  { alpha3: 'TGO', alpha2: 'TG' },
  { alpha3: 'TKL', alpha2: 'TK' },
  { alpha3: 'TON', alpha2: 'TO' },
  { alpha3: 'TTO', alpha2: 'TT' },
  { alpha3: 'TUN', alpha2: 'TN' },
  { alpha3: 'TUR', alpha2: 'TR' },
  { alpha3: 'TKM', alpha2: 'TM' },
  { alpha3: 'TCA', alpha2: 'TC' },
  { alpha3: 'TUV', alpha2: 'TV' },
  { alpha3: 'UGA', alpha2: 'UG' },
  { alpha3: 'UKR', alpha2: 'UA' },
  { alpha3: 'ARE', alpha2: 'AE' },
  { alpha3: 'GBR', alpha2: 'GB' },
  { alpha3: 'USA', alpha2: 'US' },
  { alpha3: 'UMI', alpha2: 'UM' },
  { alpha3: 'URY', alpha2: 'UY' },
  { alpha3: 'UZB', alpha2: 'UZ' },
  { alpha3: 'VUT', alpha2: 'VU' },
  { alpha3: 'VEN', alpha2: 'VE' },
  { alpha3: 'VNM', alpha2: 'VN' },
  { alpha3: 'VGB', alpha2: 'VG' },
  { alpha3: 'VIR', alpha2: 'VI' },
  { alpha3: 'WLF', alpha2: 'WF' },
  { alpha3: 'ESH', alpha2: 'EH' },
  { alpha3: 'YEM', alpha2: 'YE' },
  { alpha3: 'ZMB', alpha2: 'ZM' },
  { alpha3: 'ZWE', alpha2: 'ZW' },
]

export const COUNTRY_NAMES = {
  AFG: 'Afghanistan',
  ALA: 'Iles Åland',
  ALB: 'Albanie',
  DZA: 'Algérie',
  ASM: 'Samoa américaines',
  AND: 'Andorre',
  AGO: 'Angola',
  AIA: 'Anguilla',
  ATA: 'Antarctique',
  ATG: 'Antigua-et-Barbuda',
  ARG: 'Argentine',
  ARM: 'Arménie',
  ABW: 'Aruba',
  AUS: 'Australie',
  AUT: 'Autriche',
  AZE: 'Azerbaïdjan',
  BHS: 'Bahamas',
  BHR: 'Bahreïn',
  BGD: 'Bangladesh',
  BRB: 'Barbade',
  BLR: 'Biélorussie',
  BEL: 'Belgique',
  BLZ: 'Belize',
  BEN: 'Bénin',
  BMU: 'Bermudes',
  BTN: 'Bhoutan',
  BOL: 'Bolivie',
  BES: 'Pays-Bas caribéens',
  BIH: 'Bosnie-Herzégovine',
  BWA: 'Botswana',
  BVT: 'Ile Bouvet',
  BRA: 'Brésil',
  IOT: 'Territoire britannique de l\'océan Indien',
  BRN: 'Brunei',
  BGR: 'Bulgarie',
  BFA: 'Burkina Faso',
  BDI: 'Burundi',
  KHM: 'Cambodge',
  CMR: 'Cameroun',
  CAN: 'Canada',
  CPV: 'Cap-Vert',
  CYM: 'Iles Caïmans',
  CAF: 'République centrafricaine',
  TCD: 'Tchad',
  CHL: 'Chili',
  CHN: 'Chine',
  CXR: 'Ile Christmas',
  CCK: 'Iles Cocos',
  COL: 'Colombie',
  COM: 'Comores (pays)',
  COG: 'République du Congo',
  COD: 'République démocratique du Congo',
  COK: 'Iles Cook',
  CRI: 'Costa Rica',
  CIV: 'Côte d\'Ivoire',
  HRV: 'Croatie',
  CUB: 'Cuba',
  CUW: 'Curaçao',
  CYP: 'Chypre (pays)',
  CZE: 'Tchéquie',
  DNK: 'Danemark',
  DJI: 'Djibouti',
  DMA: 'Dominique',
  DOM: 'République dominicaine',
  ECU: 'Équateur (pays)',
  EGY: 'Égypte',
  SLV: 'Salvador',
  GNQ: 'Guinée équatoriale',
  ERI: 'Érythrée',
  EST: 'Estonie',
  ETH: 'Éthiopie',
  FLK: 'Malouines',
  FRO: 'Iles Féroé',
  FJI: 'Fidji',
  FIN: 'Finlande',
  FRA: 'France',
  GUF: 'Guyane',
  PYF: 'Polynésie française',
  ATF: 'Terres australes et antarctiques françaises',
  GAB: 'Gabon',
  GMB: 'Gambie',
  GEO: 'Géorgie (pays)',
  DEU: 'Allemagne',
  GHA: 'Ghana',
  GIB: 'Gibraltar',
  GRC: 'Grèce',
  GRL: 'Groenland',
  GRD: 'Grenade (pays)',
  GLP: 'Guadeloupe',
  GUM: 'Guam',
  GTM: 'Guatemala',
  GGY: 'Guernesey',
  GIN: 'Guinée',
  GNB: 'Guinée-Bissau',
  GUY: 'Guyana',
  HTI: 'Haïti',
  HMD: 'Iles Heard-et-MacDonald',
  VAT: 'Saint-Siège (État de la Cité du Vatican)',
  HND: 'Honduras',
  HKG: 'Hong Kong',
  HUN: 'Hongrie',
  ISL: 'Islande',
  IND: 'Inde',
  IDN: 'Indonésie',
  IRN: 'Iran',
  IRQ: 'Irak',
  IRL: 'Irlande (pays)',
  IMN: 'Ile de Man',
  ISR: 'Israël',
  ITA: 'Italie',
  JAM: 'Jamaïque',
  JPN: 'Japon',
  JEY: 'Jersey',
  JOR: 'Jordanie',
  KAZ: 'Kazakhstan',
  KEN: 'Kenya',
  KIR: 'Kiribati',
  PRK: 'Corée du Nord',
  KOR: 'Corée du Sud',
  KWT: 'Koweït',
  KGZ: 'Kirghizistan',
  LAO: 'Laos',
  LVA: 'Lettonie',
  LBN: 'Liban',
  LSO: 'Lesotho',
  LBR: 'Liberia',
  LBY: 'Libye',
  LIE: 'Liechtenstein',
  LTU: 'Lituanie',
  LUX: 'Luxembourg (pays)',
  MAC: 'Macao',
  MKD: 'Macédoine du Nord',
  MDG: 'Madagascar',
  MWI: 'Malawi',
  MYS: 'Malaisie',
  MDV: 'Maldives',
  MLI: 'Mali',
  MLT: 'Malte',
  MHL: 'Iles Marshall (pays)',
  MTQ: 'Martinique',
  MRT: 'Mauritanie',
  MUS: 'Maurice (pays)',
  MYT: 'Mayotte',
  MEX: 'Mexique',
  FSM: 'États fédérés de Micronésie (pays)',
  MAR: 'Maroc',
  MDA: 'Moldavie',
  MCO: 'Monaco',
  MNG: 'Mongolie',
  MNE: 'Monténégro',
  MSR: 'Montserrat',
  MOZ: 'Mozambique',
  MMR: 'Birmanie',
  NAM: 'Namibie',
  NRU: 'Nauru',
  NPL: 'Népal',
  NLD: 'Pays-Bas',
  NCL: 'Nouvelle-Calédonie',
  NZL: 'Nouvelle-Zélande',
  NIC: 'Nicaragua',
  NER: 'Niger',
  NGA: 'Nigeria',
  NIU: 'Niue',
  NFK: 'Ile Norfolk',
  MNP: 'Iles Mariannes du Nord',
  NOR: 'Norvège',
  OMN: 'Oman',
  PAK: 'Pakistan',
  PLW: 'Palaos',
  PSE: 'Palestine',
  PAN: 'Panama',
  PNG: 'Papouasie-Nouvelle-Guinée',
  PRY: 'Paraguay',
  PER: 'Pérou',
  PHL: 'Philippines',
  PCN: 'Iles Pitcairn',
  POL: 'Pologne',
  PRT: 'Portugal',
  PRI: 'Porto Rico',
  QAT: 'Qatar',
  REU: 'La Réunion',
  ROU: 'Roumanie',
  RUS: 'Russie',
  RWA: 'Rwanda',
  BLM: 'Saint-Barthélemy',
  SHN: 'Sainte-Hélène, Ascension et Tristan da Cunha',
  KNA: 'Saint-Christophe-et-Niévès',
  LCA: 'Sainte-Lucie',
  MAF: 'Saint-Martin',
  SPM: 'Saint-Pierre-et-Miquelon',
  VCT: 'Saint-Vincent-et-les-Grenadines',
  WSM: 'Samoa',
  SMR: 'Saint-Marin',
  STP: 'Sao Tomé-et-Principe',
  SAU: 'Arabie saoudite',
  SEN: 'Sénégal',
  SRB: 'Serbie',
  SYC: 'Seychelles',
  SLE: 'Sierra Leone',
  SGP: 'Singapour',
  SXM: 'Saint-Martin',
  SVK: 'Slovaquie',
  SVN: 'Slovénie',
  SLB: 'Iles Salomon',
  SOM: 'Somalie',
  ZAF: 'Afrique du Sud',
  SGS: 'Géorgie du Sud-et-les îles Sandwich du Sud',
  SSD: 'Soudan du Sud',
  ESP: 'Espagne',
  LKA: 'Sri Lanka',
  SDN: 'Soudan',
  SUR: 'Suriname',
  SJM: 'Svalbard et ile Jan Mayen',
  SWZ: 'Eswatini',
  SWE: 'Suède',
  CHE: 'Suisse',
  SYR: 'Syrie',
  TWN: 'Taïwan (République de Chine (Taïwan))',
  TJK: 'Tadjikistan',
  TZA: 'Tanzanie',
  THA: 'Thaïlande',
  TLS: 'Timor oriental',
  TGO: 'Togo',
  TKL: 'Tokelau',
  TON: 'Tonga',
  TTO: 'Trinité-et-Tobago',
  TUN: 'Tunisie',
  TUR: 'Turquie',
  TKM: 'Turkménistan',
  TCA: 'Iles Turques-et-Caïques',
  TUV: 'Tuvalu',
  UGA: 'Ouganda',
  UKR: 'Ukraine',
  ARE: 'Émirats arabes unis',
  GBR: 'Royaume-Uni',
  USA: 'États-Unis',
  UMI: 'Iles mineures éloignées des États-Unis',
  URY: 'Uruguay',
  UZB: 'Ouzbékistan',
  VUT: 'Vanuatu',
  VEN: 'Venezuela',
  VNM: 'Viêt Nam',
  VGB: 'Iles Vierges britanniques',
  VIR: 'Iles Vierges des États-Unis',
  WLF: 'Wallis-et-Futuna',
  ESH: 'République arabe sahraouie démocratique',
  YEM: 'Yémen',
  ZMB: 'Zambie',
  ZWE: 'Zimbabwe',
}

export function countryCodeEmoji(cc) {
  const CC_REGEX = /^[a-z]{2}$/i
  const OFFSET = 127397
  if (!CC_REGEX.test(cc)) {
    const type = typeof cc

    throw new TypeError(
      `cc argument must be an ISO 3166-1 alpha-2 string, but got '${
          type === 'string' ? cc : type
      }' instead.`
    )
  }

  const codePoints = [...cc.toUpperCase()].map(c => c.codePointAt() + OFFSET)

  return String.fromCodePoint(...codePoints)
}

export const alpha3toAlpha2CountryCode = (countryCode) => {
  return COUNTRY_CODES.find((code) => {
    return code.alpha3 === countryCode
  }).alpha2
}
