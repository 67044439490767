<template>
  <v-container>
    <v-container
      v-if="mounted"
      fluid
    >
      <v-overlay
        :value="loading"
      >
        <v-progress-circular
          indeterminate
          size="64"
        />
      </v-overlay>
      <v-row>
        <v-col class="text-right">
          <v-btn
            text
            small
            color="blue lighten-1"
            @click="editNote(null)"
          >
            Ajouter une note
          </v-btn>
        </v-col>
      </v-row>
      <v-row
        v-for="(note, index) in notes"
        :key="index"
        class="lh__note my-2"
      >
        <v-btn
          icon
          small
          color="blue lighten-1"
          @click="editNote(note)"
        >
          <v-icon small>
            mdi-comment-edit-outline
          </v-icon>
        </v-btn>
        <v-btn
          icon
          small
          color="red lighten-1"
          class="mr-2"
          @click="deleteNote(note)"
        >
          <v-icon small>
            mdi-comment-minus-outline
          </v-icon>
        </v-btn>
        <span class="font-weight-bold">{{ note.updated_at | date }} : </span>
        {{ note.text }} <span class="font-italic">({{ note.user }})</span>
      </v-row>
    </v-container>
    <NoteModal
      :opened.sync="modal.note.opened"
      :note-id:sync="editedNote.id"
      :text.sync="editedNote.text"
      @submited="saveNote"
    />
  </v-container>
</template>

<script>
import { getQueryString } from '@/helpers/queryString'
import NoteModal from '@/components/base/Modal/NoteModal.vue'
import snackbarMixin from '@/mixins/snackbar'

export default {
  name: 'Notes',
  components: {
    NoteModal,
  },
  mixins: [snackbarMixin],
  props: {
    type: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      modal: {
        note: {
          opened: false,
        },
      },
      mounted: false,
      request: null,
      loading: false,
      notes: [],
      defaultNote: '',
      editedNote: {
        id: null,
        text: '',
      },
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    cancel() {
      if (this.request) {
        this.request.cancel('aborted')
        this.request = null
      }
    },
    load() {
      this.cancel()
      let axiosSource = this.$axios.CancelToken.source()
      this.request = { cancel: axiosSource.cancel }

      this.loading = true
      this.$axios.get(
        'v3/notes?' + getQueryString(1, { type: this.type, id: this.id }),
        { cancelToken: axiosSource.token }
      )
        .then((response) => {
          this.notes = response.data['hydra:member']
        })
        .catch((error) => {
          const error_message = error.response.data['hydra:description']
              ?? error.response.data['detail']
              ?? 'No context'
          this.snackbarError(this.$i18n.t('views.notes.messages.save_failed', { error: error_message }))
        })
        .finally(() => {
          this.request = null
          this.loading = false
          this.mounted = true
        })
    },
    clearEditedNote() {
      this.editedNote.id = null
      this.editedNote.text = ''
    },
    editNote(note) {
      this.editedNote.id = note?.id ?? null
      this.editedNote.text = note?.text ?? ''
      this.modal.note.opened = true
    },
    saveNote(data) {
      this.editedNote.id === null ? this.addNote(data) : this.updateNote(data)
    },
    updateNote(data) {
      this.loading = true
      this.$axios.put('v3/notes/' + this.editedNote.id, {
        text: data.text,
      })
        .then(() => {})
        .catch((error) => {
          const error_message = error.response.data['hydra:description']
              ?? error.response.data['detail']
              ?? 'No context'
          this.snackbarError(this.$i18n.t('views.notes.messages.save_failed', { error: error_message }))
        }).finally(() => {
          this.clearEditedNote()
          this.load()
        })
    },
    addNote(data) {
      this.loading = true
      this.$axios.post('v3/notes', {
        target_id: this.id,
        target_type: this.type,
        text: data.text,
      })
        .then(() => { this.clearEditedNote() })
        .catch((error) => {
          const error_message = error.response.data['hydra:description']
              ?? error.response.data['detail']
              ?? 'No context'
          this.snackbarError(this.$i18n.t('views.notes.messages.save_failed', { error: error_message }))
        }).finally(() => {
          this.load()
        })
    },
    deleteNote(note) {
      if (confirm('Supprimer cette note : ' + note.text.substring(0, 15) + '...') === false) {
        return
      }
      this.loading = true
      this.$axios.delete('v3/notes/' + note.id, {})
        .then(() => {})
        .catch((error) => {
          const error_message = error.response.data['hydra:description']
              ?? error.response.data['detail']
              ?? 'No context'
          this.snackbarError(this.$i18n.t('views.notes.messages.save_failed', { error: error_message }))
        }).finally(() => {
          this.clearEditedNote()
          this.load()
        })
    },
  },
}
</script>

<style scoped>
.lh_note {
  white-space: pre-line;
}
</style>
