<template>
  <div>
    <v-container>
      <span class="d-block">{{ address.recipient_name }}</span>
      <span class="d-block">{{ address.line1 }}</span>
      <span
        v-if="address.line2"
        class="d-block"
      >
        {{ address.line2 }}
      </span>
      <span class="d-block">{{ address.postal_code }} {{ address.city }}</span>
      <span
        v-if="address.country_code"
        class="d-block"
      >
        {{ address.country }} {{ countryCodeEmoji(address.country_code) }}
      </span>
      <span class="d-block mt-2">{{ address.additional_information }}</span>
    </v-container>
    <v-container>
      <span class="d-block">{{ email }}</span>
      <span class="d-block">{{ phone }}</span>
    </v-container>
  </div>
</template>

<script>
import { countryCodeEmoji } from '@/helpers/countries'

export default {
  name: 'AddressDetail',
  props: {
    address: {
      type: Object,
      default: () => ({}),
    },
    email: {
      type: String,
      default: null,
    },
    phone: {
      type: String,
      default: null,
    },
  },
  methods: {
    countryCodeEmoji,
  },
}
</script>
