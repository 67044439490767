<template>
  <v-dialog
    :value="openedValue"
    max-width="900px"
    @click:outside="close()"
    @keydown.esc="close()"
  >
    <v-card
      :loading="loading"
      :disabled="loading"
    >
      <v-card-title>Création d'un nouveau colis</v-card-title>
      <v-card-text>
        <v-row
          justify="center"
          class="py-2"
          no-gutters
        >
          <v-col
            cols="12"
          >
            <v-simple-table
              class="lh_shipment__details"
            >
              <tbody>
                <tr>
                  <td>Logisticien</td>
                  <td><ProviderChip :provider="shipmentSrc.provider" /></td>
                </tr>
                <tr>
                  <td>Transporteur</td>
                  <td><CarrierChip :carrier="shipmentSrc.carrier" /></td>
                </tr>
                <tr>
                  <td>Produits</td>
                  <td>
                    <ItemAvatar
                      v-for="item in items"
                      :key="item.id"
                      :product="item.product"
                    />
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-subtitle>
        Vous aurez la possibilité de modifier les adresses d'expéditeur et de destinataire
        dans le détail du nouveau colis.
      </v-card-subtitle>
      <v-card-actions>
        <v-btn
          outlined
          color="red darken-2"
          @click="close()"
        >
          <v-icon left>
            mdi-cancel
          </v-icon>
          {{ $t('forms.buttons.cancel') }}
        </v-btn>
        <v-spacer />
        <v-btn
          raised
          color="green darken-1"
          class="white--text"
          @click="save()"
        >
          <v-icon left>
            mdi-check-outline
          </v-icon>
          Créer
        </v-btn>
      </v-card-actions>
    </v-card>
    <ConfirmModal ref="confirm" />
  </v-dialog>
</template>

<script>
import CarrierChip from '@/components/base/Shipment/CarriererChip.vue'
import ConfirmModal from '@/components/base/Modal/ConfirmModal.vue'
import ItemAvatar from '@/views/Shipments/Edit/ItemAvatar.vue'
import ProviderChip from '@/components/base/Shipment/ProviderChip.vue'

export default {
  name: 'MoveToNewOneModal',
  components: { ItemAvatar, ConfirmModal, CarrierChip, ProviderChip },
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
    shipmentSrc: {
      type: Object,
      default: () => ({}),
    },
    items: {
      type: Array,
      default: () => ([]),
    },
  },
  data() {
    return {
      loading: false,
      message: {
        type: null,
        text: null,
        link: null,
      },
    }
  },
  computed: {
    openedValue: {
      get() {
        return this.opened
      },
      set(value) {
        this.$emit('update:opened', value)
      },
    },
  },
  methods: {
    show(loading) {
      this.openedValue = true
      this.loading = loading
    },
    hide() {
      this.openedValue = false
      this.loading = false
      this.message = { type: null, text: null, link: null }
    },
    close() {
      this.$emit('shipmentUpdated', this.message)
      this.hide()
    },
    async save() {
      this.hide()
      
      if (await this.$refs.confirm.open(
        this.$i18n.t('forms.buttons.validate'),
        this.$i18n.t('views.shipment.forms.messages.move_items_to_new_one',
          { nb: this.items.length }))
      ) {
        this.show(true)
        if (confirm) {
          this.$axios.post('v3/shipments/' + this.shipmentSrc.id + '/reorganize', {
            items_id: this.items.map((item) => item.id),
          })
            .then((response) => {
              this.message.type = 'green'
              this.message.text = this.$i18n.t('views.shipment.forms.messages.duplication_succeed')
              this.message.link = '/shipments/' + response?.data?.id
            })
            .catch((error) => {
              // eslint-disable-next-line no-console
              console.log(error)
              let error_message = error.response.data['hydra:description']
                ?? error.response.data['detail'] ?? 'No context'
              this.message.type = 'error'
              this.message.text = this.$i18n.t('views.shipment.forms.messages.duplication_failed',
                { error: error_message })
            })
            .finally(
              this.close
            )
        } else {
          this.close()
        }
      }
    },
  },
}
</script>

<style scoped>
.lh_shipment__details tr td:first-child {
  font-weight: bolder;
  width: 150px;
}
</style>
